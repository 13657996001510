import * as React from 'react';
import { SearchContainer } from '../../Containers/containersBarrel';

import './helpStyles.css';

export const Help = (props) => (
  <div className="HelpPage">
    <div className="BackgroundImageSection">
      <div className="SearchAndTitleContainer">
        <SearchContainer className="Search" location={props.location.pathname} resetLocalStorage="true" />
      </div>
    </div>
    <div className="TextContainer container">
      <div className="HelpUsTextContainer">
        <span className="HelpUsText">SBL Member Notifications</span>
        <div className="row">
          <h3>Book/Review Notifications</h3>
          <p>
            As an <a 
              target="_blank" 
              className="link" 
              rel="noopener noreferrer" 
              href={"https://www.sbl-site.org/membership/joinnow.aspx"}
            >
              SBL member
            </a>
            , you can choose to be notified by email when books and book reviews are added in your subject areas of interest. These notifications are processed weekly. To take advantage of this benefit, login and select Manage Book Notifications by clicking on the down arrow next to your name.
          </p>
            <a   
              rel="noopener noreferrer" 
              href={"https://vimeo.com/672855833"}
            ><u>Video: Adding/Managing Book and Review Notifications</u></a>
        </div>
        {/* Commented below code on 01/25/2025 as per SBLC-65 */}
        {/* <div className="row">
          <h3>Journal Notifications</h3>
          <p>
          As an <a 
              target="_blank" 
              className="link" 
              rel="noopener noreferrer" 
              href={"https://www.sbl-site.org/membership/joinnow.aspx"}
            >
              SBL member
            </a>
            , you can choose to receive timely email notifications providing the contents of your journals of interest as new issues are released. To take advantage of this benefit, login and select Manage Journal Notifications by clicking on the down arrow next to your name.
          </p>
          <a   
              rel="noopener noreferrer" 
              href={"https://vimeo.com/672858806"}
            ><u>Video: Adding/Managing Journal Notifications</u></a>
        </div> */}
      </div>
      <div className="HelpUsTextContainer">
        <span className="HelpUsText">Search Help</span>
        <div className="row">
          <h3>Capitalization</h3>
          <p>Searches are not case sensitive. For example, a search for <em>Job</em> returns the same results as a search for <em>JOB</em> or <em>job</em>.</p>
        </div>
        <div className="row">
          <h3>Keyword as prefix terms</h3>
          <p>Keywords, or portions of keywords, entered into the keyword search bar are treated as “prefix terms.” For example, if you search for <em>con</em>, you will get search results that include words which begin with these three characters, such as construing, consultation, contrast, and contextual.</p>
          <p>The more characters you enter, the more precise your search will be. For example, searching for <em>con</em> might return 4000 search results, searching for <em>context</em> might return 1400 search results, and searching for <em>contextual</em> might return 400 results.</p>
        </div>
        <div className="row">
          <h3>Search with multiple keywords</h3>
          <p>To improve the precision of your search, search multiple keywords. For example, searching for <em>contextual, interpretation</em>, with a comma and/or a space between these search terms, will return search results that include both contextual AND interpretation.</p>
        </div>
        <div className="row">
          <h3>Combine searches</h3>
          <p>You can enter OR between any two words. For example, <em>contextual OR interpretation.</em></p>
        </div>
        <div className="row">
          <h3>Exclude words from your search</h3>
          <p>Enter <em>AND NOT</em> in front of a word you want to leave out. For example, <em>contextual AND NOT interpretation.</em></p>
        </div>
        <div className="row">
          <h3>Search for an exact match</h3>
          <p>Put a word or phrase inside quotes. For example, <em>“prophetic texts”</em>. (Note that words or phrases entered in quotation marks are treated as whole words, not prefix terms.)</p>
        </div>
        <div className="row">
          <h3>Searchable Data</h3>
          <p>When searching for paper abstracts, the following are searched: titles, presenters, program units, meeting names, meeting locations, and where applicable, subjects.</p>
          <p>
            When searching for books, the following are searched: titles, subtitles, descriptions, authors, editors, translators, publishers, subjects, keywords, copyright years, and ISBNs.
          </p>
        </div>
        <div className="row">
          <h3>Why can’t I see search results for paper abstracts prior to 2004?</h3>
          <p>SBL first took the Call for Papers process online in 2004. Prior meeting data is not currently available in digital format.</p>
        </div>
        <div className="row">
          <h3>Why can’t I see subjects associated with paper abstracts prior to 2015?</h3>
          <p>In 2015, SBL added functionality to its online Call for Papers interface to allow presenters to tag their paper proposals with subjects. Such functionality was not previously available.</p>
        </div>
        <p><a   
            rel="noopener noreferrer" 
            href={"https://vimeo.com/672855138"}
          ><u>Video: Searching for Books</u></a>
        </p>
        <p><a   
            rel="noopener noreferrer" 
            href={"https://vimeo.com/672855644"}
          ><u>Video: Searching for Paper Abstracts</u></a>
        </p>
      </div>
    </div>
  </div>
);
